var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        attrs: {
          id: "cancellation-reasons",
          centered: "",
          title: "Cancellation of Services",
          size: "lg",
          "no-close-on-esc": "",
          "no-close-on-backdrop": "",
        },
        scopedSlots: _vm._u([
          {
            key: "modal-footer",
            fn: function () {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "grey-button-outlined mr-2",
                    attrs: {
                      variant: "outline-secondary",
                      "aria-label": "Back to service button",
                    },
                    on: { click: _vm.onClose },
                  },
                  [
                    _c("fa-icon", {
                      staticClass: "fa-lg mr-2",
                      attrs: { icon: "chevron-left" },
                    }),
                    _c("span", [_vm._v("Back to Services")]),
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "custom-red-button mr-3",
                    attrs: { "aria-label": "Confirm cancellation button" },
                    on: { click: _vm.cancellationFeedback },
                  },
                  [_vm._v("\n        Confirm Cancellation\n      ")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _vm.selectedBizIdServices?.length
                  ? _c(
                      "b-alert",
                      {
                        staticClass: "d-flex align-items-center m-3",
                        attrs: { variant: "danger", show: "" },
                      },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: {
                            icon: "exclamation-triangle-fill",
                            variant: "warning",
                          },
                        }),
                        !_vm.selectedHostingTypes && _vm.sslSelected
                          ? _c("div", [
                              _vm._v(
                                "\n          Any website or email service associated with a cancelled SSL service will "
                              ),
                              _c("strong", [
                                _vm._v("lose their secure encryption"),
                              ]),
                              _vm._v(".\n        "),
                            ])
                          : _c("div", [
                              _c("strong", [
                                _vm._v(
                                  "All " +
                                    _vm._s(_vm.selectedHostingTypes) +
                                    " data will be deleted"
                                ),
                              ]),
                              _vm._v(
                                " from our database after 45 days.\n          Reactivate service from Websites/Email tab at any time.\n        "
                              ),
                            ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "services-container" },
                  _vm._l(_vm.companies, function (company, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "service-card" },
                      [
                        _c("div", { staticClass: "card-header" }, [
                          _vm._v(_vm._s(company.name)),
                        ]),
                        _vm._l(
                          company.services,
                          function (service, service_index) {
                            return _c(
                              "div",
                              {
                                key: service_index,
                                staticClass: "service-row",
                              },
                              [
                                _c("span", [
                                  _c("p", { staticClass: "service-name" }, [
                                    _vm._v(_vm._s(service?.product?.name)),
                                  ]),
                                  _c("p", { staticClass: "jurisdiction" }, [
                                    _vm._v(
                                      _vm._s(service?.jurisdiction?.formatted)
                                    ),
                                  ]),
                                ]),
                                _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "remove-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeService(service.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("fa-icon", {
                                        staticClass: "fa-lg mr-2",
                                        attrs: { icon: "minus-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "cancellation-info-container" }, [
                  _c("div", { staticClass: "cancellation-info-text" }, [
                    _c("h5", [
                      _vm._v(
                        "\n            Heads up! Cancelling Services will result in...\n          "
                      ),
                    ]),
                    _c(
                      "ul",
                      _vm._l(_vm.cancellationInfoText, function (item, index) {
                        return _c("li", { key: index }, [
                          _vm._v(
                            "\n              " + _vm._s(item) + "\n            "
                          ),
                        ])
                      }),
                      0
                    ),
                    _c("span", [
                      _vm._v(
                        "Our services are hassle free and make your life easier. "
                      ),
                      _c("strong", [_vm._v("Do you wish to continue?")]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "reason-container" },
                  [
                    _c(
                      "b-form",
                      { on: { submit: _vm.cancellationFeedback } },
                      [
                        _c(
                          "b-form-group",
                          [
                            _c("label", { staticClass: "label-sm" }, [
                              _vm._v("Reason for Cancellation"),
                            ]),
                            _c(
                              "b-form-select",
                              {
                                staticClass: "select-box",
                                model: {
                                  value: _vm.feedBack,
                                  callback: function ($$v) {
                                    _vm.feedBack = $$v
                                  },
                                  expression: "feedBack",
                                },
                              },
                              _vm._l(
                                _vm.cancellationOptions,
                                function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.cancellation_reason,
                                      domProps: { value: option.id },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(option.cancellation_reason) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm.isOtherOption
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "label-sm mt-3" },
                                      [
                                        _vm._v(
                                          "Please explain in 100 characters or less"
                                        ),
                                      ]
                                    ),
                                    _c("b-form-textarea", {
                                      attrs: {
                                        id: "textarea",
                                        placeholder: "Enter something...",
                                        rows: "3",
                                        "max-rows": "6",
                                        required: "",
                                        maxlength: "100",
                                      },
                                      model: {
                                        value: _vm.feedBackIfOther,
                                        callback: function ($$v) {
                                          _vm.feedBackIfOther = $$v
                                        },
                                        expression: "feedBackIfOther",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.servicesIncludesVirtualOffice
                              ? _c("div", [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "label-sm mt-3 text-danger",
                                    },
                                    [
                                      _vm._v(
                                        "Warning: Cancelling a Virtual Office service will cancel associated Virtual Phone services."
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "cancel-services",
            "hide-footer": "",
            title: "Service Cancellation Agreement",
            size: _vm.size,
          },
        },
        [
          _c(
            "div",
            { staticClass: "agreement-modal" },
            [
              _vm.cancelError
                ? _c("banner", {
                    staticClass: "mb-2",
                    attrs: {
                      message:
                        "There was an issue processing your cancellation, please try again or contact customer service",
                      icon: ["fas", "times-circle"],
                      "icon-color": "#CB2328",
                      "banner-background-color": "#FFEBEE",
                      "banner-border-color": "#CB2328",
                      status: "Failed",
                    },
                  })
                : _vm._e(),
              _vm.outstandingBalance > 0
                ? _c(
                    "div",
                    [
                      _c("p", [
                        _vm._v(
                          "\n          You are cancelling your subscription. We charge by the day and your pro-rated amount is:\n        "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Balance Due: " + _vm._s(_vm.outstandingBalance)
                        ),
                      ]),
                      _c("select-payable", {
                        attrs: { "invoice-view": false },
                        on: { payableInfo: _vm.payableInfo },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeFilings.length > 0 || _vm.loadingActiveFilings
                ? _c(
                    "div",
                    [
                      _c("p", [
                        _c("strong", [_vm._v("Active Filings")]),
                        _c("br"),
                        !_vm.loadingActiveFilings
                          ? _c("span", [
                              _vm._v(
                                "\n            The following filings are affected by cancelling these services. Select ones you would like to cancel.\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("ct-table", {
                        ref: "activeFilingsTable",
                        attrs: {
                          items: _vm.activeFilings,
                          fields: _vm.filingFields,
                          loaded: _vm.filingsLoaded,
                          "selectable-function": _vm.selectableFunction,
                          selectable: true,
                          "select-mode": "multi",
                          "is-paginated": false,
                        },
                        on: { "selection-changed": _vm.selectionEvent },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("service-cancellation-text", {
                attrs: { services: _vm.selectedServices },
              }),
              _c("strong", [_vm._v("Representative")]),
              _c("p", { staticClass: "m-b-lg" }, [
                _c("i", [
                  _vm._v(
                    "\n          By typing your name, you are agreeing to the end of service agreement. You are agreeing this\n          is the equivalent of a traditional signature. This will cancel the selected services with\n          us.\n        "
                  ),
                ]),
              ]),
              _c(
                "b-form-group",
                [
                  _c("label", { staticClass: "label-lg" }, [
                    _vm._v("* Enter your name to agree"),
                  ]),
                  _c("b-form-input", {
                    staticClass: "form-control",
                    attrs: { type: "text", required: "" },
                    model: {
                      value: _vm.signature,
                      callback: function ($$v) {
                        _vm.signature = $$v
                      },
                      expression: "signature",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4 submit-btn-width",
                      attrs: {
                        variant: "danger",
                        disabled:
                          _vm.submitBtnDisabled ||
                          _vm.loading ||
                          _vm.loadingActiveFilings,
                      },
                      on: { click: _vm.cancelServices },
                    },
                    [
                      _vm.loading
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("div", [
                            _vm.selection.length > 0
                              ? _c("div", [
                                  _vm._v(
                                    "\n              I agree, cancel my services and filings\n            "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    "\n              I agree, cancel my services\n            "
                                  ),
                                ]),
                          ]),
                    ],
                    1
                  ),
                  _vm.outstandingBalance > 0
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mt-4 submit-btn-width ml-3",
                          attrs: {
                            variant: "dark",
                            disabled:
                              _vm.withoutPayingBtnDisabled ||
                              _vm.loading ||
                              _vm.loadingActiveFilings,
                          },
                          on: { click: _vm.cancelServicesWithoutPaying },
                        },
                        [
                          _vm.loading
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _c("div", [
                                _vm._v(
                                  "\n            I agree, cancel without paying\n          "
                                ),
                              ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }